.searchBar {
    position: relative;
}
.searchList {
    position: absolute;
    top: 100%;
    font-size: 18px;
    margin-bottom: 5px;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 100px;
    color: black;
    overflow-y: scroll;
    z-index: 1;
  }
.searchListItems:hover {
    background-color: rgb(179, 178, 178);
    cursor: pointer;
}